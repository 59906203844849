/*global jQuery */
/* Contents
// ------------------------------------------------>
	1.  Background INSERT
	2.  HEADER AFFIX
	3.	AJAX MAILCHIMP
	4.  AJAX CAMPAIGN MONITOR 
	5.  OWL CAROUSEL
	6.  SCROLL TO
	7.  WOW
	8.  Youtube Background
*/
(function ($) {
    "use strict";

    /* ------------------  Background INSERT ------------------ */

    var $bgSection = $(".bg-section");

    $bgSection.each(function () {
        var bgSrc = $(this).children("img").attr("src");
        var bgUrl = 'url(' + bgSrc + ')';
        $(this).parent().css("backgroundImage", bgUrl);
        $(this).parent().addClass("bg-section");
        $(this).remove();
    });

    /* ------------------ HEADER AFFIX ------------------ */

    // var $navAffix = $(".header-fixed .navbar-fixed-top");
    // $navAffix.affix({
    //     offset: {
    //         top: 50
    //     }
    // });






    /* ------------------  SCROLL TO ------------------ */

    var aScroll = $('a[data-scroll="scrollTo"]');
    aScroll.on('click', function (event) {
        var target = $($(this).attr('href'));
        if (target.length) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000);
        }
    });

    /* ------------------  WOW Animated ------------------ */
    var wow = new WOW({

        boxClass: 'wow', // animated element css class (default is wow)
        animateClass: 'animated', // animation css class (default is animated)
        offset: 50, // distance to the element when triggering the animation (default is 0)
        mobile: false, // trigger animations on mobile devices (default is true)
        live: true // act on asynchronously loaded content (default is true)

    });

    wow.init();

    /* ------------------  Youtube Background  ------------------ */
    // $(".bg-ytvideo").each(function () {

    //     var vidId = $(this).data("vid-id"),
    //         vidAutoPlay = $(this).data("autoplay"),
    //         vidStartAt = $(this).data("start-at"),
    //         vidMute = $(this).data("mute"),
    //         vidOpacity = $(this).data("opacity"),
    //         vidShowPluginLogo = $(this).data("plugin-logo"),
    //         vidShowControls = $(this).data("controls"),
    //         vidFallBackImg = $(this).data("fall-cover");

    //     if (vidAutoPlay === "" || vidAutoPlay === null || vidAutoPlay === undefined) {
    //         vidAutoPlay = true;
    //     }
    //     if (vidStartAt === "" || vidStartAt === null || vidStartAt === undefined) {
    //         vidStartAt = 0;
    //     }
    //     if (vidMute === "" || vidMute === null || vidMute === undefined) {
    //         vidMute = true;
    //     }
    //     if (vidOpacity === "" || vidOpacity === null || vidOpacity === undefined) {
    //         vidOpacity = 1;
    //     }
    //     if (vidShowPluginLogo === "" || vidShowPluginLogo === null || vidShowPluginLogo === undefined) {
    //         vidShowPluginLogo = false;
    //     }
    //     if (vidShowControls === "" || vidShowControls === null || vidShowControls === undefined) {
    //         vidShowControls = false;
    //     }
    //     if (vidFallBackImg === "" || vidFallBackImg === null || vidFallBackImg === undefined) {
    //         vidFallBackImg = "";
    //     }

    //     $(this).data(
    //         "property",
    //         "{videoURL:'http://youtu.be/" + vidId + "',containment:'self',autoPlay:" + vidAutoPlay + ", mute:" + vidMute + ", startAt:" + vidStartAt + ", opacity:" + vidOpacity + ",showYTLogo:" + vidShowPluginLogo + ",showControls:" + vidShowControls + ",stopMovieOnBlur:false,mobileFallbackImage:'" + vidFallBackImg + "'}"
    //     );
    // });

    // $(".bg-ytvideo").mb_YTPlayer();

    /* ------------------  MAGNIFIC POPUP VIDEO ------------------ */

    $('.popup-video,.popup-gmaps').magnificPopup({
        disableOn: 700,
        mainClass: 'mfp-fade',
        removalDelay: 0,
        preloader: false,
        fixedContentPos: false,
        type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                }
            },
            srcAction: 'iframe_src',
        }
    });



    /* ------------------ TYPED SCRIPT ------------------ */

    // $(".typed-text").each(function () {
    //     var $string = $(this).data("typed-string") ? $(this).data("typed-string").split(",") : [];
    //     console.log($string);
    //     $(this).typed({
    //         strings: $string,
    //         typeSpeed: 60
    //     });

    // });

    if ($( "#typed" ).length ) {

        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            typeSpeed: 30
        });

    }

    

}(jQuery));